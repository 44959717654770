/* You can add global styles to this file, and also import other style files */
.text-left {
    overflow: hidden;
}
body {
    margin: 0;
    background: #3c3c3c;
    font-size:14px !important;
}

#wide iframe {
    width: 100% !important;
    height: 99% !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
}

.transitions {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.google-map-wrapper {
    -webkit-transition: height 0.5s ease;
    -moz-transition: height 0.5s ease;
    -o-transition: height 0.5s ease;
    transition: height 0.5s ease;
    overflow: hidden;
}
.google-map-wrapper {
    height:400px;
    overflow:auto;
    background: #F3F1ED;
    position: relative;
}
.google-map-wrapper.for-messages{
    margin-bottom: 100px;
}
.google-map-container {
    height: 300px;
}

.bold {
    font-weight: bold;
}
.pointer {
    cursor: pointer!important;
}
.hidden {
    display: none;
}
.no-padding {
    padding:0px !important;
}
.fill {
    display:block;
    min-height: 100%;
    height: 100%;
}
.full{
    width: 100%;
    min-width:100%;
    display:block
}

#dashboard-carList-div {
    width: 100%;
}
#dashboard-carList {
    background: url("./assets/icons-new/truck.svg") no-repeat center center;
    background-size: 40px 40px;
}
#dashboard-carLoc {
    background: url("./assets/icons-new/all-in-one-map.svg") no-repeat center center;
    background-size: 40px 40px;
}
#dashboard-dispBoard {
    background:  url("./assets/icons-new/table.svg") no-repeat center center;
    background-size: 40px 40px;
}
#dashboard-diary {
    background: url("./assets/icons-new/diare.svg") no-repeat center center;
    background-size: 40px 40px;
}
#dashboard-obligation-diary {
    background: url("./assets/icons-new/notebook3.svg") no-repeat center center;
    background-size: 40px 40px;
}
#dashboard-msgRead {
    background: url("./assets/icons-new/mail_open2.svg") no-repeat center center;
    background-size: 40px 40px;
}
#dashboard-msgNew {
    background: url("./assets/icons-new/mail.svg") no-repeat center center;
    background-size: 40px 40px;
}
#dashboard-settings {
    background: url("./assets/icons-new/setup.svg") no-repeat center center;
    background-size: 40px 40px;
}

.dashboard-head {
    height: 52px;
    background-color: #F2F2F2;
    margin-right: 15px;
    margin-left: -1px;
    position: fixed;
    line-height: 50px;
    top: 0;
    z-index: 2;
    font-size: 30px;
}
.dashboard-head h4 {
    margin: 0;
    height: 100%;
    line-height: 50px;
    font-size: 30px;
}
.dashboard-head.messages-read a,
.dashboard-head.messages-new a {
    color: white;
}
.dashboard-head.itinerary-dairy {
    position: fixed;
    background: #2873ad;
    color: white;
}
.dashboard-head.itinerary-dairy .rightMenuImg, .dashboard-head.itinerary-dairy a {
    background: none;
    color: white;
}
.dashboard-head h4 > * {
    vertical-align: middle;
}
.dashboard-head.message-history {
    background: white;
}
.dashboard-head.messages-read, .dashboard-head.messages-new {
    background-color: #1BA388;
    background-image: none;
    color: white;
    border: 1px #CCCCCC solid;
}
.dashboard-head .rightMenuImg {
    cursor:default;
}

.dashboard-body {
    margin-top: 52px;
}

.rightMenuImg{
    cursor: pointer;
    float: left;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    font-size: 34px;
    text-align: center;
    position: relative;
}

.vehicle-status {
    position: relative;
    width: 100%;
}
.vehStatusIcon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: calc(50% - 15px);
}

div.agenda-detail img.icon {
    width: 20px;
}
div.agenda-detail table tr td:first-child {
    text-transform: capitalize;
}
div.agenda-detail {
    overflow-y: auto;
    height: 300px;
}

/* Common messages dirty stuff */
messages-read .day-group, messages-day-grouped-list .day-group {
    background: #1ba388;
    color: white;
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.message {
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #ccc;
    background: #fafafa;
}
.message.yellow {
    background: #f3ee99;
}

.message.grey {
    background: #CFD8DC;
}
.message-reply {
    padding: 0px;
    height: 0px;
    overflow: hidden;
}
.message-reply textarea {
    width: 100%;
    height: 75px;
    resize: none;
}

.file-input-container input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.file-input-container {
    display: inline-block;
    /* width: 100px; */
    max-width: 100%;
    border-radius: 5px;
    background: #337ab7;
    overflow: hidden;
    position: relative;
    text-align: center;
    /* padding: 2px 5px; */
    color: white;
}
a.button {
    display: inline-block;
    background: #ccc;
    padding: 2px 5px;
    min-width: 100px;
    text-align: center;
    border-radius: 5px;
}
.message-reply.open {
    padding: 15px 0px;
    height: 100%;
}

.message.not-to-car-d,.messages-dialog .not-to-car-d{
    background: #cfd8dc;
}
.message.to-car-c,.messages-dialog .to-car-c,.message.not-to-car-c,.messages-dialog .not-to-car-c{
    background: #97def4;
}
.modal-backdrop.in {
    opacity: 0.8;
}


.messages-dialog > .row{
    background: #cfd8dc;
}
.messages-dialog .message {
    border: none;
}

.messages-dialog > .row {
    border-bottom: 1px solid #ccc;
}
a.button.button-background-primary {
    background-color: #2dc74e;
    color: rgb(0, 0, 0);
}

a.button.button-background-secondary {
    background-color: #e23237;
    color: white;
}

.vehicle-messages-fixed-footer.col-12 {
    position: absolute;
    bottom: 0px;
    width: 100%;
    opacity: 1;
    height: 100px;
    background: #eee;
}

.message.system, .messages-dialog .system {
    background: #FFFFA0;
}

.message.system-order, .messages-dialog .system-order {
    background: rgb(255, 179, 186, 0.4);
}

.modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}
object.document {
    height: calc(100vh - 275px);
}

tr.even td, td.even {
    background: #dcddde;
}
.message-attachment {
    width: 100%;
}

.modal-body.messages-dialog {
    padding-top: 0px;
    padding-bottom: 0px;
}

.vehicle-diagnostics-component .table-bordered {
    border-color: #333;
    word-wrap: break-word;
}

.data-column.pull-left {
    width: 4.5%;
    position: relative;
    height: 100%;
    padding: 0.3%;
}
.date {
    transform: rotate(300deg);
    position: absolute;
    bottom: 25px;
    height: 20px;
    left: -15px;
}

.activity {
    height: 75%;
}
.activity-type-S {
    background: grey;
}
.activity-type-D {
    background: #2873ae;
}

.activity-type-P {
    background: #f0ad4e;
}
.vehicle-activity-component hr,.vehicle-activity-component span {
    position: absolute;
    z-index: 0;
    left: -30px;
    font-size: 80%;
}
.vehicle-activity-component hr {
    width: 100%;
    left: -5px;
    border: 1px solid #5a5a5a;
}

.vehicle-activity-component {
    padding-left: 40px;
    position: relative;
}
.activity > div {
    z-index: 2;
}

/* google map options icons */
.mapAdditionalFunctions {
    position: absolute;
    display: block;
    bottom: 25px;
    left: 5px;
}
.mapAdditionalFunctions img {
    width: 30px;
    height: 30px;
    padding: 4px;
    margin: 5px;
    background: white;
}

/* custom small style for google maps api infowindow */
.gm-style-iw {
    padding: 2px !important;
    /* padding: 2px 14px 2px 2px !important; */
}
.gm-style-iw-d {
    overflow: hidden !important;
}
.gm-style-iw-c {
    overflow: hidden !important;
    z-index: 1;
}
.gm-style-iw-chr {
    display: none !important;
}

.modal-body.dispositions-dialog {
    max-height: calc(100vh - 310px);
}

.modal-dialog {
    max-height: calc(100vh - 50px);
}
.modal-lg {
    width: 100%;
    max-width:100%;
}

@media (max-width: 1200px) {
    .modal-lg, .modal-xl {
        max-width: 100% !important;
    }
}

label[for] {
    cursor : pointer;
}
a.dispositions-current-pdf-close.btn.btn-danger.text-white {
    position: absolute;
    /* width: 25px;
    height: 25px; */
    font-size: 15px;
    padding: 0;
    display: block;
    line-height: 12px;
}
details > div.vehicleAdditionals {
    position: absolute;
    z-index: 100;
    background: white;
    width: 170px;
    left: -149px;
    border: 1px solid;
    top: -23px;
}

summary::-webkit-details-marker {
    display: none
}
@media screen and (max-width: 600px) {
    .progress-bar {
        animation: none;
        background-image: none;
    }
    .modal-dialog {
        width:100%;
        margin: 0px;
    }
    .dashboard-head {
        font-size:25px;
    }
}


/******************************************************************************/
/* Styly leve casti */
.pointer {
    cursor: pointer;
}
.normal-break {
    white-space: normal;
}
.normal-break * {
    white-space: normal;
}

/* prizpusobeni prvku bootstrapu - predevsim rem -> px */
.input-group-sm > .form-control {
    font-size: 14px !important;
    padding: 4px 8px !important;
}
.custom-select-sm {
    font-size: 14px !important;
    padding: 4px 8px !important;
}

.input-group-text {
    font-size: 14px !important;
}

.normal-font-size {
    font-size: 14px;
}
.small-font-size {
    font-size: 11px;
}

.btn {
    font-size: 14px !important;
    padding: 6px 12px !important;
}
.btn-sm {
    font-size: 14px !important;
    padding: 4px 8px !important;
}

.form-control {
    font-size: 14px !important;
    padding: 6px 12px !important;
}
.form-control-sm {
    padding: 4px 8px !important;
}
.form-group {
    font-size: 14px !important;
}

/* tooltips */
.tooltip-inner {
    font-size: 14px !important;
    max-width: 650px !important;
    text-align: left !important;
}
.tooltip-inner img.icon {
    width: 16px;
    height: 16px;
    display: inline-block;
}
.tooltip-inner img.icon-lg {
    width: 25px;
    height: 25px;
    display: inline-block;
}

/* popovers */
.popover {
    max-width: 650px !important;
}
.popover-body {
    max-width: 650px !important;
}
.popover-header {
    font-size: 14px !important;
    /* white-space: pre-wrap !important; */
}
.popover-body {
    font-size: 14px !important;
    /* white-space: pre-wrap !important; */
}
.arrow {
    z-index: 100000;
}

/* Moje auta + servis - nastaveni */
.nav-underline .nav-link.active {
    background-color: transparent !important;
    color: #de8d00 !important;
    border-bottom: 2px solid #de8d00 !important;
}
.nav-underline .nav-link:hover {
    border-bottom: 2px solid #de8d00 !important;
}

ul.nav.nav-tabs {
    background-color: #f7f7f7;
}

select.keep-selected option:checked { 
    background: #de8d00 !important;
    color: #ffffff !important;
}
select.custom-invalid, input.custom-invalid {
    border-color: #dc3545 !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-shadow: 0 0 0 1px #dc3545;
}

tr.custom-invalid {
    background-color: #f8d7da !important;
    border-color: #f5c6cb !important;
}
tr.custom-shared {
    background-color: rgb(0, 123, 255, 0.25) !important;
    border-color: rgb(0, 123, 255, 0.22) !important;
}

/* vlastni styl pro autocompleter typeahead */
/* .dropdown-menu {
    font-size: 14px;
} */
/* .dropdown-item {
    font-size: 14px;
} */
ngb-typeahead-window .dropdown-menu {
    font-size: 14px;
    min-width: 500px;
}
ngb-typeahead-window .dropdown-item {
    font-size: 14px;
    min-width: 500px;
}
ngb-modal-window + ngb-typeahead-window.dropdown-menu {
    z-index: 1051;
}
body > ngb-typeahead-window.dropdown-menu.show {
    z-index: 1051;
}


/* blok pro spinner s nacitanim */
.spinner-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
}

/* styl je prizpusoben pro zarovnani custom-switch */
.custom-control-label {
    margin-bottom: .5rem !important;
}

.popoverMenuItem a {
    color: #212529;
    text-decoration: none;
}
.popoverMenuItem:hover {
    background-color: #cacaca;
    border-radius: 4px;
    cursor: pointer;
}

.statusMenuItem {
    padding: 0.25rem 0.5rem !important;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #9d9d9d;
}
.statusMenuItem:hover {
    opacity: 0.9;
    border: 1px solid #4a4a4a;
}

.smallMarginFormGroup .form-group label {
    margin-bottom: 2px !important;
}

.smallPaddingVertical {
    padding-top: 1px;
    padding-bottom: 1px;
}

.striped .row:nth-of-type(odd) > div {
    background-color: rgba(0,0,0,.05);
}
.striped .row:nth-of-type(even) > div {
    background-color: #FFFFFF;
}
.striped > div {
    border: 1px solid transparent;
}
.striped > div:hover {
    border: 1px solid rgba(0,0,0,.80);
}

th.sortHeader {
    background: #343a40 !important;
}

/* obligation status P */
.grayCyanBackground { background-color: rgb(161, 235, 232) !important; }
.grayCyanBackgroundRow { background-color: rgb(161, 235, 232, 0.5); }
.grayCyanBackgroundRow:hover { background-color: rgb(161, 235, 232, 0.7) !important; }

/* obligation status E */
.yellowBackground { background-color: rgb(254, 211, 161) !important; }
.yellowBackgroundRow { background-color: rgb(254, 211, 161, 0.5); }
.yellowBackgroundRow:hover { background-color: rgb(254, 211, 161, 0.7) !important; }

/* obligation status A */
.blueBackground { background-color: rgb(153, 202, 255) !important; }
.blueBackgroundRow { background-color: rgb(153, 202, 255, 0.5); }
.blueBackgroundRow:hover { background-color: rgb(153, 202, 255, 0.7) !important; }

/* obligation status G */
.cyan1Background { background-color: rgb(132, 212, 201) !important; }
.cyan1BackgroundRow { background-color: rgb(132, 212, 201, 0.5); }
.cyan1BackgroundRow:hover { background-color: rgb(132, 212, 201, 0.7) !important; }

/* obligation status H */
.cyan2Background { background-color: rgb(51, 184, 165) !important; }
.cyan2BackgroundRow { background-color: rgb(51, 184, 165, 0.5); }
.cyan2BackgroundRow:hover { background-color: rgb(51, 184, 165, 0.7) !important; }

/* obligation status J */
.cyan3Background { background-color: rgb(0, 168, 143) !important; }
.cyan3BackgroundRow { background-color: rgb(0, 168, 143, 0.5); }
.cyan3BackgroundRow:hover { background-color: rgb(0, 168, 143, 0.7) !important; }

/* obligation status B */
.redBackground { background-color: rgb(255, 179, 186) !important; }
.redBackgroundRow { background-color: rgb(255, 179, 186, 0.5); }
.redBackgroundRow:hover { background-color: rgb(255, 179, 186, 0.7) !important; }
/* other row versions used in invoice list */
.redBackgroundRow20 { background-color: rgb(255, 179, 186, 0.2); }
.redBackgroundRow20:hover { background-color: rgb(255, 179, 186, 0.7) !important; }
.redBackgroundRow40 { background-color: rgb(255, 179, 186, 0.4); }
.redBackgroundRow40:hover { background-color: rgb(255, 179, 186, 0.7) !important; }
.redBackgroundRow70 { background-color: rgb(255, 179, 186, 0.7); }
.redBackgroundRow70:hover { background-color: rgb(255, 179, 186, 0.85) !important; }

/* obligation status C */
.purpleBackground { background-color: rgb(226, 189, 227) !important; }
.purpleBackgroundRow { background-color: rgb(226, 189, 227, 0.5); }
.purpleBackgroundRow:hover { background-color: rgb(226, 189, 227, 0.7) !important; }
/* other row versions used in invoice list */
.purpleBackgroundRow20 { background-color: rgb(226, 189, 227, 0.2); }
.purpleBackgroundRow20:hover { background-color: rgb(226, 189, 227, 0.7) !important; }
.purpleBackgroundRow40 { background-color: rgb(226, 189, 227, 0.4); }
.purpleBackgroundRow40:hover { background-color: rgb(226, 189, 227, 0.7) !important; }
.purpleBackgroundRow70 { background-color: rgb(226, 189, 227, 0.7); }
.purpleBackgroundRow70:hover { background-color: rgb(226, 189, 227, 0.85) !important; }

/* obligation status F */
.greenBackground { background-color: rgb(112, 193, 129) !important; }
.greenBackgroundRow { background-color: rgb(112, 193, 129, 0.5); }
.greenBackgroundRow:hover { background-color: rgb(112, 193, 129, 0.7) !important; }

/* order status L */
.greenLightBackground { background-color: rgb(182, 238, 194) !important; }
.greenLightBackgroundRow { background-color: rgb(182, 238, 194, 0.5); }
.greenLightBackgroundRow:hover { background-color: rgb(182, 238, 194, 0.7) !important; }

/* obligation status D */
.grayBackground { background-color: rgb(180, 180, 180) !important; }
.grayBackgroundRow { background-color: rgb(180, 180, 180, 0.5); }
.grayBackgroundRow:hover { background-color: rgb(180, 180, 180, 0.7) !important; }

/* obligation status D */
.lightGrayBackground { background-color: rgb(205, 205, 205) !important; }
.lightGrayBackgroundRow { background-color: rgb(205, 205, 205, 0.5); }
.lightGrayBackgroundRow:hover { background-color: rgb(205, 205, 205, 0.7) !important; }

/* used in service tasks detail */
.orangeBackground { background-color: rgb(255, 193, 7) !important; }
.orangeBackgroundRow { background-color: rgb(255, 193, 7, 0.5); }
.orangeBackgroundRow:hover { background-color: rgb(255, 193, 7, 0.7) !important; }

.whiteDefaultRow { background-color: rgb(245, 245, 245); }
.whiteDefaultRow:hover { background-color: rgb(225, 225, 225); }


/* Styles for custom input range */
/* https://github.com/angular-slider/ng5-slider/blob/HEAD/STYLING.md */
.ng5-slider .ng5-slider-bubble {
    font-size: 14px !important;
}


/* particular modals and other parent styles */
/* that could not be styled in component.css */
#obligationsEModal .card-header {
    padding: 0.5rem !important;
}
#obligationsFavouriteModal .card-header {
    padding: 0.5rem !important;
}

/* Material styles
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/* Customizing mat-calendar */
.mat-calendar-header {
    padding: 0px 8px 0 8px !important;
}
.mat-calendar-table-header th {
    padding: 0 0 2px 0 !important;
}
.mat-calendar-controls {
    margin: 0px !important;
}
.mat-calendar-content {
    padding: 0px 8px 0px 8px !important;
}
.mat-calendar-body-label[colspan="7"] {
    display: none;
}
.mat-calendar-body-label{
    opacity: 0;
}


/* Google maps Places autocompleter fix not showin in modal */
/* https://stackoverflow.com/questions/20185606/google-places-autocomplete-not-showing-up */
.pac-container { z-index: 10000 !important; }

/* Google maps info window hide close button */
/* https://stackoverflow.com/questions/18933367/gmaps-v3-infowindow-disable-the-close-x-button */
button.gm-ui-hover-effect { 
    display: none !important; 
    visibility: hidden; /* this 2 lines below are just for hard hiding. :) */
    opacity: 0;
}


/* TOREMOVE !!! */

/* TOREMOVE !? */
.progress {
    width: 40px;
    height: 12px;
    margin: auto;
    border: 1px solid #4D4D4D;
    border-radius: 0px;
    height: 10px;
}

/* TOREMOVE! this crazy checkbox customization */
.checkbox {
    padding-left: 20px; 
}
.checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 5px; 
}
.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out; 
}
.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555; 
}
.checkbox input[type="checkbox"] {
    opacity: 0; 
}
.checkbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
.checkbox input[type="checkbox"]:checked + label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
}
.checkbox input[type="checkbox"]:disabled + label {
    opacity: 0.65; 
}
.checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #eeeeee;
    cursor: not-allowed; 
}



/***************************************************************************/
/* Paragraph backgrounds in PDF analysis */
/***************************************************************************/
.par-gray-light {
    background-color: #b9b3b3 !important;
    padding: 1px;
    border-radius: 4px;
}
.par-gray-dark {
    background-color: #8b8a8a !important;
    padding: 1px;
    border-radius: 4px;
}

.par-blue-light {
    background-color: #79b3ff;
    padding: 1px;
    border-radius: 4px;
}
.par-blue-dark {
    background-color: #1a73e8;
    padding: 1px;
    border-radius: 4px;
}

.par-purple-light {
    background-color: #fe75ee;
    padding: 1px;
    border-radius: 4px;
}
.par-purple-dark {
    background-color: #d922c3;
    padding: 1px;
    border-radius: 4px;
}

.par-green-light {
    background-color: #BDE3C5;
    padding: 1px;
    border-radius: 4px;
}
.par-green-dark {
    background-color: #5db06f;
    padding: 1px;
    border-radius: 4px;
}

.par-yellow-light {
    background-color: #FFEE7C;
    padding: 1px;
    border-radius: 4px;
}
.par-yellow-dark {
    background-color: #e9ce1c;
    padding: 1px;
    border-radius: 4px;
}

.par-border {
    border: 1px dashed #a0a0a0;
}


.nav-link-loading.active {
    color: #fff !important;
    background-color: #4fad3c !important;
}
.nav-link-unloading.active {
    color: #000 !important;
    background-color: #e4ce0d !important;
}


.accordion > .card > .card-header {
  padding: 0 0.25rem !important;
}
.custom-accordion .card-body {
  padding: 0 !important;
}